<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="5"></user-left>
        </div>
        <div class="col-10 f-14">
          <div class="row bg-white rounded ml-2">
            <div
              class="p-3 border-bottom col-12 d-flex justify-content-between"
            >
              <h6>资金账户</h6>
              <div class="ml-1">
              <span> 账户余额：</span><span class="text-danger">1980元</span>  <span class="badge badge-danger f-14 pl-2 pr-2 ml-1">充值</span>

              <span class="ml-2">可提现余额：</span><span class="text-success">0元</span> <span class="badge badge-success f-14 pl-2 pr-2 ml-1">提现</span>
              </div>
            </div>
            <div class="col-12 mt-3">
              <b-tabs lazy>
                <b-tab title="账单明细">
                     <div class="w-100">
                    <b-alert class="mt-2 d-flex justify-content-between align-items-center" show>
                        <div>
                            <div class="clearfix d-flex align-items-center">
                                <b-dropdown text="状态" variant="outline-secondary" size="sm" class="mr-2">
                                    <b-dropdown-item class="secondary">提现</b-dropdown-item>
                                    <b-dropdown-item class="secondary">充值</b-dropdown-item>
                                    <b-dropdown-item class="secondary">支付</b-dropdown-item>
                                    <b-dropdown-item class="secondary">退还</b-dropdown-item>
                                    <b-dropdown-item class="secondary">收入</b-dropdown-item>
                                </b-dropdown>
                                
                                <div class="float-left">
                                    <b-form-input type="date"></b-form-input>
                                </div>
                                <div class="float-left ml-2 mr-2">至</div>
                                <div class="float-left">
                                    <b-form-input type="date"></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div class="text-secondary">
                            <span>收入：+0</span>
                            <span>,</span>
                            <span>支出:-1000</span>
                        </div>
                    </b-alert>
              <div class="card border-0 mt-3 mb-3">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-centered border mb-0">
                      <thead class="table-light">
                        <tr>
                          <th>流水号</th>
                          <th>日期</th>
                          <th>分类</th>
                          <th>金额</th>
                          <th>操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in 10" :key="i">
                          <td>
                              40597375837664785939
                          </td>
                          <td>
                              2021-07-31 10:29
                           </td>
                          <td>
                              充值
                          </td>
                          <td>
                              <b>+2000元</b>
                          </td>
                          <td>
                              <a href="javascript:void(0);" class="ml-2">
                                <b-icon icon="trash"></b-icon>  
                              </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div></div>
                    <div>
                      <b-pagination
                        class="mt-1 mb-1"
                        :total-rows="10"
                        :per-page="1"
                        aria-controls="my-table"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
                <!-- end card-body-->
              </div>
                  </div>
                </b-tab>



                <b-tab title="余额明细">
                    <div class="w-100">
                   <b-alert class="mt-2 d-flex justify-content-between align-items-center" show>
                        <div>
                            <div class="clearfix d-flex align-items-center">
                                <b-dropdown text="状态" variant="outline-secondary" size="sm" class="mr-2">
                                    <b-dropdown-item class="secondary">提现</b-dropdown-item>
                                    <b-dropdown-item class="secondary">充值</b-dropdown-item>
                                    <b-dropdown-item class="secondary">支付</b-dropdown-item>
                                    <b-dropdown-item class="secondary">退还</b-dropdown-item>
                                    <b-dropdown-item class="secondary">收入</b-dropdown-item>
                                </b-dropdown>
                                
                                <div class="float-left">
                                    <b-form-input type="date"></b-form-input>
                                </div>
                                <div class="float-left ml-2 mr-2">至</div>
                                <div class="float-left">
                                    <b-form-input type="date"></b-form-input>
                                </div>
                            </div>
                        </div>
                        <div class="text-secondary">
                            <span>收入：+0</span>
                            <span>,</span>
                            <span>支出:-1000</span>
                        </div>
                    </b-alert>
                  
              <div class="card border-0 mt-3 mb-3">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-centered border mb-0">
                      <thead class="table-light">
                        <tr>
                          <th>流水号</th>
                          <th>日期</th>
                          <th>分类</th>
                          <th>金额</th>
                          <th>操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in 10" :key="i">
                          <td>
                              40597375837664785939
                          </td>
                          <td>
                              2021-07-31 10:29
                           </td>
                          <td>
                              充值
                          </td>
                          <td>
                              <b>+2000元</b>
                          </td>
                          <td>
                              <a href="javascript:void(0);" class="ml-2">
                                <b-icon icon="trash"></b-icon>  
                              </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div></div>
                    <div>
                      <b-pagination
                        class="mt-1 mb-1"
                        :total-rows="10"
                        :per-page="1"
                        aria-controls="my-table"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
                <!-- end card-body-->
              </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
.form-control {
  height: 31px;
}

</style>
